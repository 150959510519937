<template>
  <CModal
    :title="$t('sub_options')"
    size="xl"
    color="info"
    :addContentClasses="'modal-offset-1'"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('extra_option')"
          :placeholder="$t('extra_option')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="modal.data.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="getExtraOptions()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="10" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="() => createExtraOptionModal = { data: { extra_id: modal.data.extra_id, parent_id: modal.data.id }, modal: true }">{{ $t('create_sub_option') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
        {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
      </CBadge>
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editExtraOptionModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
          <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteExtraOptionModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <div slot="footer-wrapper"></div>

    <CreateExtraOptionModal :data="createExtraOptionModal.data" :show="createExtraOptionModal.modal" @showUpdate="show => createExtraOptionModal.modal = show" @formSubmit="getExtraOptions()" />

    <EditExtraOptionModal :data="editExtraOptionModal.data" :show="editExtraOptionModal.modal" @showUpdate="show => editExtraOptionModal.modal = show" @formSubmit="getExtraOptions()" />

    <DeleteExtraOptionModal :data="deleteExtraOptionModal.data" :show="deleteExtraOptionModal.modal" @showUpdate="show => deleteExtraOptionModal.modal = show" @formSubmit="getExtraOptions()" />

  </CModal>
</template>

<script>
import CreateExtraOptionModal from '../modal/CreateExtraOptionModal'
import EditExtraOptionModal from '../modal/EditExtraOptionModal'
import DeleteExtraOptionModal from '../modal/DeleteExtraOptionModal'

export default {
  name: 'ExtraSubOptionsModal',
  components: {
    CreateExtraOptionModal,
    EditExtraOptionModal,
    DeleteExtraOptionModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: [ 'name', 'price', 'sort', 'enabled', 'action' ],
        data: [],
        options: {
          headings: {
            name: this.$t('name'),
            price: this.$t('price'),
            sort: this.$t('sort'),
            enabled: this.$t('enable'),
            action: this.$t('action')
          },
          // orderBy: { ascending: true, column: 'sort' },
          sortable: [ 'name', 'price', 'sort', 'enabled' ],
          filterable: [ 'name', 'price' ]
        },
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      extraSubOptionsModal: { data: {}, modal: false },
      createExtraOptionModal: { data: {}, modal: false },
      editExtraOptionModal: { data: {}, modal: false },
      deleteExtraOptionModal: { data: {}, modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.searchData = { extra_id: this.modal.data.extra_id, parent_id: this.modal.data.id }
        this.getExtraOptions()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getExtraOptions () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getExtraOptions', this.$_.clone(this.searchData)).then(res => {
        this.table.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  },
  beforeDestroy: function () {
  },
}
</script>
