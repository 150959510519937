<template>
  <CModal
    :title="modal.data.parent_id ? $t('edit_sub_option') : $t('edit_option')"
    size="xl"
    color="info"
    :addContentClasses="modal.data.parent_id ? 'modal-offset-2' : 'modal-offset-1'"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('price')"
          :placeholder="$t('price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="0"
          v-model="formData.price"
          :isValid="valid_formData_price"
        />
      </CCol>
      <CCol col="12" sm="12" v-if="!formData.parent_id">
        <CSelect
          :label="$t('required')"
          :options="requiredOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.required"
          :isValid="valid_formData_required"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.enabled"
          :isValid="valid_formData_enabled"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditExtraOptionModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      requiredOptions: [
        { value: 1, label: this.$t('yes') },
        { value: 0, label: this.$t('no') }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showExtraOption()
      }
    }
  },
  computed: {
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_price () {
      return (this.formData.price && this.formData.price > 0) || this.formData.price == 0 ? true : false
    },
    valid_formData_required () {
      return this.formData.required === 0 || this.formData.required === 1 ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_submit () {
      return this.valid_formData_name && this.valid_formData_price && this.valid_formData_required && this.valid_formData_enabled
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showExtraOption () {
      const loader = this.$loading.show()
      this.$store.dispatch('showExtraOption', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateExtraOption', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
