<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10"></CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getExtras()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createExtraModal = { modal: true }">{{ $t('create_extra') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => extraImagesModal = { data: props.row, modal: true }">{{ $t('images') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => extraOptionsModal = { data: props.row, modal: true }">{{ $t('options') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editExtraModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteExtraModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateExtraModal :data="createExtraModal.data" :show="createExtraModal.modal" @showUpdate="show => createExtraModal.modal = show" @formSubmit="getExtras()" />

      <EditExtraModal :data="editExtraModal.data" :show="editExtraModal.modal" @showUpdate="show => editExtraModal.modal = show" @formSubmit="getExtras()" />

      <DeleteExtraModal :data="deleteExtraModal.data" :show="deleteExtraModal.modal" @showUpdate="show => deleteExtraModal.modal = show" @formSubmit="getExtras()" />

      <ExtraImagesModal :data="extraImagesModal.data" :show="extraImagesModal.modal" @showUpdate="show => extraImagesModal.modal = show" @formSubmit="getExtras()" />

      <ExtraOptionsModal :data="extraOptionsModal.data" :show="extraOptionsModal.modal" @showUpdate="show => extraOptionsModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreateExtraModal from './modal/CreateExtraModal'
import EditExtraModal from './modal/EditExtraModal'
import DeleteExtraModal from './modal/DeleteExtraModal'
import ExtraImagesModal from './modal/ExtraImagesModal'
import ExtraOptionsModal from './modal/ExtraOptionsModal'

export default {
  name: 'extrasSetting',
  components: {
    CreateExtraModal,
    EditExtraModal,
    DeleteExtraModal,
    ExtraImagesModal,
    ExtraOptionsModal,
  },
  data () {
    return {
      columns: [ 'name', 'sort', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          name: this.$t('name'),
          labels: this.$t('labels'),
          introduction: this.$t('introduction'),
          sort: this.$t('sort'),
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'sort', 'enabled', 'created_at', 'enabled' ],
        filterable: [ 'name', 'sort', 'enabled', 'created_at', 'enabled' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      extraImagesModal: { data: {}, modal: false },
      extraOptionsModal: { data: {}, modal: false },
      createExtraModal: { data: {}, modal: false },
      editExtraModal: { data: {}, modal: false },
      deleteExtraModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getExtras()
  },
  methods: {
    getExtras () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getExtras', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
