<template>
  <CModal
    :title="$t('edit_extra')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <h4 class="mb-3">{{ $t('extra_information') }}</h4>
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('date_selection_mode')"
          :options="dateModeOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.date_mode"
          :isValid="valid_formData_date_mode"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('date_calculation_mode')"
          :options="dateCalcModeOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.date_calc_mode"
          :isValid="valid_formData_date_calc_mode"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ $t('description') }}
          </label>
          <div class="col-sm-12">
            <TinymceEdit editorClass="edit-tinymce-container" :content="formData.description || ''" @contentUpdate="content => formData.description = content" />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('sort')"
          :placeholder="$t('sort')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="0"
          v-model="formData.sort"
          :isValid="valid_formData_sort"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.enabled"
          :isValid="valid_formData_enabled"
        />
      </CCol>
    </CRow>

    <CRow v-for="(extra_quantity_unit, idx) in formData.extra_quantity_units" :key="idx">
      <CCol col="12" sm="12">
        <hr>
      </CCol>
      <CCol col="12" sm="10">
        <h4 class="mb-3">{{ $t('extra_quantity_unit') }} {{ idx + 1 }}</h4>
      </CCol>
      <CCol col="12" sm="2" class="text-right">
        <CButton
          color="info"
          v-bind="{ shape: 'pill' }"
          @click="formData.extra_quantity_units.push({ id: null, name: '', measure: '', stock: 0, combo_discount: 0, enabled: 1, extra_prices: [...holidayTypeOptions.map(e => { return { id: null, holiday_type_id: e.value, holiday_type: { name: e.label }, price: 0 } })] })"
          v-if="idx == 0"
        >
          <CIcon name="cil-plus" />
        </CButton>
        <CButton
          color="danger"
          v-bind="{ shape: 'pill' }"
          @click="formData.extra_quantity_units.splice(idx, 1)"
          v-if="idx > 0"
        >
          <CIcon name="cil-trash" />
        </CButton>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('quantity_unit_name')"
          :placeholder="$t('quantity_unit_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="extra_quantity_unit.name"
          :isValid="validString(extra_quantity_unit.name)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('quantity_unit')"
          :placeholder="$t('quantity_unit')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="extra_quantity_unit.measure"
          :isValid="validString(extra_quantity_unit.measure)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('stock')"
          :placeholder="$t('stock')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="1"
          v-model="extra_quantity_unit.stock"
          :isValid="validNumber(extra_quantity_unit.stock)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('combo_discount')"
          :placeholder="$t('combo_discount')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="0"
          v-model="extra_quantity_unit.combo_discount"
          :isValid="validNumberOrZero(extra_quantity_unit.combo_discount)"
        />
      </CCol>
      <!-- <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="extra_quantity_unit.enabled"
          :isValid="validEnabled(extra_quantity_unit.enabled)"
        />
      </CCol> -->
      <CCol col="12" sm="12">
        <h5 class="mb-3">{{ $t('extra_price') }}</h5>
        <CRow v-for="(extra_price, extraPriceIdx) in extra_quantity_unit.extra_prices" :key="extraPriceIdx">
          <CCol col="12" sm="12">
            <CInput
              :label="extra_price.holiday_type.name"
              :placeholder="$t('price')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              min="1"
              v-model="extra_price.price"
              :isValid="validNumber(extra_price.price)"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
import TinymceEdit from '@/views/tinymce/TinymceEdit'

export default {
  name: 'EditExtraModal',
  components: {
    TinymceEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      dateModeOptions: [
        { value: 'range', label: this.$t('range') },
        { value: 'single', label: this.$t('single') }
      ],
      dateCalcModeOptions: [
        { value: 'range_minus_one', label: this.$t('range_day_minus_one') },
        { value: 'single', label: this.$t('single_day') }
      ],
      holidayTypeOptions: [],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showExtra()
      }
    }
  },
  computed: {
    valid_formData_date_mode () {
      return this.formData.date_mode ? true : false
    },
    valid_formData_date_calc_mode () {
      return this.formData.date_calc_mode ? true : false
    },
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_description () {
      return this.formData.description ? true : false
    },
    valid_formData_sort () {
      return /^[0-9]+$/.test(this.formData.sort) ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_extra_quantity_units () {
      return this.formData.extra_quantity_units && this.formData.extra_quantity_units.length > 0 && this.formData.extra_quantity_units.every(e => {
        return e.name && e.measure && e.stock && e.stock > 0 && ((e.combo_discount && e.combo_discount > 0) || e.combo_discount == 0) && (e.enabled === 0 || e.enabled === 1) && e.extra_prices && e.extra_prices.length > 0 && e.extra_prices.every(e => {
          return e.holiday_type_id && e.price && e.price > 0
        })
      })
    },
    valid_submit () {
      return this.valid_formData_date_mode && this.valid_formData_date_calc_mode && this.valid_formData_name && this.valid_formData_description && this.valid_formData_sort && this.valid_formData_enabled && this.valid_extra_quantity_units
    }
  },
  mounted: function () {
    this.getHolidayTypes()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getHolidayTypes () {
      const loader = this.$loading.show()
      this.$store.dispatch('getHolidayTypes') .then(res => {
        this.holidayTypeOptions = []
        for (const i in res) {
          this.holidayTypeOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    showExtra () {
      const loader = this.$loading.show()
      this.$store.dispatch('showExtra', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateExtra', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },

    validString (v) {
      return v ? true : false
    },
    validNumberOrZero (v) {
      return (v && v > 0) || v == 0 ? true : false
    },
    validNumber (v) {
      return v && v > 0 ? true : false
    },
    validEnabled (v) {
      return v === 0 || v === 1 ? true : false
    },
  },
  beforeDestroy: function () {
  },
}
</script>
